/* ./src/index.css */
/* button{
    outline: none !important;
    border: none,
}

input {
    outline: none;
    border: none
}

textarea {
    outline: none;
    border: none
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

